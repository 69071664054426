<template>
  <div class="input__numeric-wrapper">
    <v-text-field
      ref="input"
      v-bind="$props"
      class="input__numeric"
      hide-details="auto"
      dense
      :background-color="background"
      :suffix="suffix"
      :error="error"
      outlined
      @input="onInput"
      @change="onChange"
    />
    <span
      v-if="pretty"
      :class="background"
      @click="$refs.input.focus()"
    >{{ pretty }}
      <span
        v-if="suffix"
        :class="background"
        class="float-end pl-1"
      >{{ suffix }}
      </span>
    </span>
  </div>
</template>
<script>
import {normalizeFloat, prettifyNumber} from "@/helpers/math";

export default {
  props: {
    value: {
      validator: (v) =>
        typeof v === "string" || typeof v === "number" || v === null,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    normalizer: {
      type: Function,
      required: false,
      default: null,
    },
    fraction: {
      validator: (v) => typeof v === "number" || v === null,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: null,
    },
    backgroundColor: {
      required: false,
      type: String,
      default: null
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    suffix: {
      validator: (v) => typeof v === "string" || v === null,
      required: false,
      default: null
    },
    error: {
      required: false,
      type: Boolean,
      default: false,
    },
    label:{
      required: false,
      type: String,
      default: null,
    }
  },
  data: () => ({
    key: 0,
    width: 0,
  }),
  computed: {
    background() {
      return this.backgroundColor ? this.backgroundColor : (this.disabled ? 'grey lighten-2' : 'white')
    },
    pretty() {
      return prettifyNumber(this.value)
    },
  },
  methods: {
    onInput(value) {
      const normalized = value.replace(",", ".").trim()
      this.$emit("input", normalized);
    },
    onChange(value) {
      const numeric = value.toString().replace(/[^0-9.]/g, '')

      const normalized = this.normalizer !== null
        ? this.normalizer(value)
        : normalizeFloat({value: numeric, afterPoint: this.fraction})
      this.$emit("input", normalized)
      this.$emit("change", normalized);
    },
  },
};
</script>
<style scoped>
.input__numeric-wrapper {
  position: relative;
}

.input__numeric.v-input--is-focused + span {
  display: none;
}

.input__numeric + span:hover {
  cursor: text;
}

.input__numeric + span {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  position: absolute;
  /*padding: 6px 10px;
  margin: 2px;*/
  margin-top: 8px;
  margin-left: 12px;
  top: 0;
  white-space: nowrap;
  overflow-x: scroll;
  /*width: calc(100% - 10px);*/
  width: calc(100% - 22px);
  opacity: 1;
}

span::-webkit-scrollbar {
  display: none;
}
</style>
