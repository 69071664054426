export function getNextPaymentType(paymentType){
  let next = null
  switch (paymentType) {
  case "БН":
    next = "БЗ";
    break;
  case "НР":
    next = "НЗ";
    break;
  case "ПК":
    next = "КЗ";
    break;
  case "ПС":
    next = "СЗ";
    break;
  }
  return next
}

export function getAvailablePaymentModes(types) {
  const availableCodes = ["БН", "НР", "ПК", "ПС", "ОБ", "ВЗ"]
  return types.filter(type => availableCodes.includes(type.code))
}

export function paymentOrderDebitDefaultState() {
  return {
    id: null,
    payment_order_id: null,
    sum: 0,
    declaration_id: null,
    payment_type: null,
    code:null,
    remark:null
  }
}

export function paymentOrderDefaultState() {
  return {
    id: null,
    created: null,
    code: null,
    sum: null,
    currency_letter: null,
    unp: null,
    payment_doc_number: null,
    payment_date: null,
    personal_number: null,
    payment_type: null,
    bank_name: null,
    remark: null,
    remains: null,
    owner_name:null,
  }
}

function hasEmptyFields(paymentOrderState) {
  return Object.keys(paymentOrderState)
    .filter(key => !NOT_REQUIRED_FIELDS.concat(OWNER_IDENTITY_FIELDS).includes(key))
    .map((key) => paymentOrderState[key])
    .some(value => value === null || value === "")
}
function isEmptyOwner(ownerIdentity) {
  return !ownerIdentity
}
function isEmptySum(paymentOrderState) {
  return !paymentOrderState.sum
}

export function sortByPriority(paymentOrders, code){
  let valid = [], invalid = []
  paymentOrders.forEach((order) => {
    order.code === code ? valid.push(order) : invalid.push(order)
  })
  valid.sort((a, b) => b.id - a.id)
  invalid.sort((a, b) => {
    return a.code === b.code ? b.id - a.id : +a.code - +b.code
  })
  return valid.concat(invalid)
}

export function isValidPaymentOrderForm({paymentOrderState, ownerIdentity}){
  if (isEmptySum(paymentOrderState)){
    this.$warning("Сумма не может быть равна нулю")
    return false
  }
  if (isEmptyOwner(ownerIdentity)){
    this.$warning("Не заполнен плательщик")
    return false
  }
  if (hasEmptyFields(paymentOrderState)){
    this.$warning("Не заполнены обязательные поля")
    return false
  }
  return true
}

export const NOT_REQUIRED_FIELDS = ['id', 'created', 'remark', 'bank_name', 'remains', 'owner_name', 'trash']
export const OWNER_IDENTITY_FIELDS = ["personal_number", "unp"]