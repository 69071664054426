<template>
  <div class="d-flex align-center">
    <v-text-field
      :background-color="disabled ? 'grey lighten-2' : 'white'"
      type="date"
      :label="label"
      hide-details
      dense
      outlined
      :class="inputClass"
      :value="value"
      :disabled="disabled"
      @change="onChange($event)"
      @input="onInput($event)"
      @paste="onPaste"
      @copy="onCopy"
    >
      <template #append>
        <v-menu
          v-model="menu"
          offset-y
          left
          min-width="auto"
          content-class="prevent-trigger-update"
          :close-on-content-click="false"
          class="relative"
        >
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              tabindex="-1"
              :disabled="disabled"
              v-on="on"
            >
              mdi-calendar-blank-outline
            </v-icon>
          </template>
          <v-date-picker
            class="pb-3 pt-1"
            no-title
            color="#5cb7b1"
            :value="value"
            show-current
            @input="onPickerInput($event)"
            @click:year="onYearSelect($event)"
            @click:month="onMonthSelect($event)"
            @click:date="menu = false"
          />

          <v-btn
            absolute
            class="today-button rounded-t-0 white--text"
            color="#5cb7b1"
            @click="today"
          >
            Сегодня
          </v-btn>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import {deleteNulls} from "@/helpers/inputs";
import moment from 'moment';
import {copy, paste} from "@/helpers/text";

export default {
  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || prop === null
    },
    visited: {
      required: false,
      type: Boolean
    },
    hasChanges: {
      required: false,
      type: Boolean
    },
    inputClass: {
      type: String,
      default: ""
    },
    disabled: {
      required: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      menu: false,
    }
  },
  methods: {
    today() {
      const value = moment().format('YYYY-MM-DD')
      this.$emit('input', value)
      this.$emit('handleChange')
      this.$emit('change', value)
      if (!this.visited) this.$emit('update:visited', true)
      if (!this.hasChanges) this.$emit('update:has-changes', true)
      this.menu = false
    },
    async onPaste() {
      const value = await paste()
      if (moment(value, 'DD.MM.YYYY').isValid()) {
        this.onPickerInput(moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD'))
      } else {
        this.$snackbar({
          text: 'Неверный формат даты',
          color: 'red',
          top: false,
        })
      }
    },
    onCopy(event) {
      const value = event.target.value
      if (moment(value, 'YYYY-MM-DD').isValid()) {
        copy(moment(value).format('DD.MM.YYYY'))
      }
    },
    onInput(e) {
      this.$emit('input', e)
    },
    onPickerInput(e) {
      this.$emit('input', e)
      this.$emit('handleChange')
      this.$emit('change', e)
      if (!this.visited) this.$emit('update:visited', true)
      if (!this.hasChanges) this.$emit('update:has-changes', true)
    },
    prepareDate(date){
      const preparedDate = deleteNulls(date)
      const isValid = moment(preparedDate, 'YYYY-MM-DD', true).isValid()
      if(preparedDate && !isValid){
        this.$warning("Некорректная дата")
        return null
      }
      return preparedDate
    },
    onChange(e) {
      const value = this.prepareDate(e)
      this.$emit('input', value)
      this.$emit('change', value)
      this.$emit('handleChange', value)
    },
    onMonthSelect(e) {
      const [year, , date] = this.value.split('-')
      const [, month] = e.split('-')
      const result = [year, month, date].join('-')
      this.$emit('input', result)
      if (!this.visited) this.$emit('update:visited', true)
      if (!this.hasChanges) this.$emit('update:has-changes', true)
    },
    onYearSelect(e) {
      const [, month, date] = this.value.split('-')
      const result = [e, month, date].join('-')
      this.$emit('input', result)
      if (!this.visited) this.$emit('update:visited', true)
      if (!this.hasChanges) this.$emit('update:has-changes', true)
    }
  }
}
</script>
<style scoped>
.relative {
  position: relative;
}

.today-button {
  bottom: 0;
  width: 100%;
  left: 0;
}
</style>
