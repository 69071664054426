const filterBySearchFieldMixin = {
  methods: {
    filterBySearchField(item, queryText) {
      return (
        item.search.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1
      );
    },
  },
};

export { filterBySearchFieldMixin };
