<template>
  <v-overlay :value="show">
    <v-card
      light
      class="pb-5"
      color="#333333"
      :width="width"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <slot name="header" />
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-progress-linear
        v-if="loading"
        color="teal"
        indeterminate
      />
      <div class="white pa-5 rounded-0">
        <slot name="body" />
        <div>
          <slot name="footer" />
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import {disablePageScroll} from "@/events/common";
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";

export default {
  mixins: [closeOnEscapeMixin],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    width:{
      type:Number,
      required:false,
      default:1300
    }
  },
  watch: {
    show: {
      handler(value) {
        disablePageScroll.trigger(value)
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
}
</script>
